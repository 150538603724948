<template>
    <div class="hero-text-wrapper">
        <h1>{{ banner.title_2 }}</h1>
        <h2>{{ banner.title_1 }}</h2>
        <p class="lg">
            {{ banner.description }}
        </p>
        <router-link
            v-slot="{ navigate }"
            :to="banner.link"
            custom
        >
            <b-button
                variant="primary"
                class="fill lg"
                @click="navigate"
            >
                <AnchorIcon /><span>{{ $t('page.home.banner.button') }}</span>
            </b-button>
        </router-link>
    </div>
</template>

<script>
    import AnchorIcon from 'vue-feather-icons/icons/AnchorIcon';
    export default {
        name: 'HomeHeroText',
        components: {
            AnchorIcon,
        },
        props: {
            banner: {
                type: Object,
                required: true,
            },
        },
    };
</script>

<style scoped lang="scss">
.hero-text-wrapper {
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-down(md) {
    margin-top: 32px;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
    padding: 0px 16px;
  }
  p,
  .btn {
    order: 3;
    width: fit-content;
  }
  h1 {
    order: 2;
  }
  h2 {
    order: 1;
    color: $text;
  }
}
</style>
