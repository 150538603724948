<template>
    <div class="category">
        <div
            class="category-card"
            @click="navigateToCategory(category.path)"
        >
            <div class="image">
                <v-lazy-image
                    :src="category.thumbnails['h-300']"
                    :src-placeholder="category.thumbnails['h-75']"
                    loading="lazy"
                    height="360"
                    width="270"
                    :alt="'categorie-' + category.name"
                />
            </div>
        </div>
        <h4>{{ category.name }}</h4>
    </div>
</template>

<script>
    import VLazyImage from 'v-lazy-image/v2';
    export default {
        name: 'CategoryCard',
        components: {
            VLazyImage,
        },
        props: {
            category: {
                type: Object,
                required: true,
            },
        },
        methods: {
            navigateToCategory(path) {
                this.$router.push(`/categorie/${path}`);
            },
        },
    };
</script>

<style scoped lang="scss">
.category {
  border: none;
  .image {
    position: relative;
    aspect-ratio: 3 / 4 !important;
    overflow: hidden;
    border-radius: 8px;
    transition: box-shadow 300ms ease-in-out;
    cursor: pointer;
    img {
      width: 100%;
      height: auto;
      transition: transform 300ms ease-in-out;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(19, 28, 33, 0.25);
      opacity: 75%;
      border-radius: 8px;
    }
  }
  h4 {
    margin-top: 16px;
    transition: color 300ms ease-in-out;
  }
  @include media-breakpoint-up(md) {
    &:hover {
      .image {
        box-shadow: $shadow-menu;
        &::after {
          display: none;
        }
        img {
          -moz-transform: scale(1.05);
          -webkit-transform: scale(1.05);
          -o-transform: scale(1.05);
          -ms-transform: scale(1.05);
          -webkit-transform: scale(1.05);
          transform: scale(1.05);
        }
      }
      h4 {
        color: $secondary;
      }
    }
  }
}
</style>
