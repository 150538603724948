<template>
    <ContentLoader
        primary-color="#f6f7f8"
        secondary-color="#eef0f2"
        width="100"
        height="100"
        preserveAspectRatio="none"
        :speed="1"
    >
        <rect
            x="2"
            y="2"
            rx="1"
            ry="1"
            width="96%"
            height="96%"
        />
    </ContentLoader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader';

    export default {
        name:'HomeHeroBannerLoader',
        components: {
            ContentLoader,
        },
    };
</script>