<template>
    <div
        class="animate-scroll"
        :class="{ show: showMouse }"
    >
        <p class="sm-sbold">
            {{ $t('page.home.banner.scroll') }}
        </p>
        <span class="mouse">
            <span
                class="scroll"
                :class="scrollDirection"
            />
        </span>
        <p class="sm-sbold">
            {{ $t('page.home.banner.scrollDown') }}
        </p>
    </div>
</template>
<script>
    export default {
        name: 'HomeHeroScroll',
        data() {
            return {
                scrolled: false,
                showMouse: false,
                countDown: false,
                scrollDirection: 'up',
            };
        },
        methods: {
            handleScroll() {
                if (window.scrollY > 650 && !this.scrolled) {
                    this.scrolled = true;
                    this.showMouse = false;
                } else if (window.scrollY <= 650 && this.scrolled) {
                    this.scrolled = false;
                    if (!this.countDown) {
                        this.checkScroll();
                    }
                }
            },
            checkScroll() {
                if (!this.countDown) {
                    setTimeout(() => {
                        if (!this.scrolled) {
                            this.showMouse = true;
                        }
                        this.countDown = false;
                    }, 3000);
                }
            },
            animate(direction) {
                this.scrollDirection = direction ? 'up' : 'down';
                if (this.showMouse) {
                    setTimeout(() => {
                        this.animate(!direction);
                    }, 600);
                }
            },
        },
        mounted() {
            this.checkScroll();
        },
        created() {
            if (
                this.$screen.breakpoint == 'lg' ||
                this.$screen.breakpoint == 'xl' ||
                this.$screen.breakpoint == 'xxl'
            ) {
                window.addEventListener('scroll', this.handleScroll);
            }
        },
        destroyed() {
            if (
                this.$screen.breakpoint == 'lg' ||
                this.$screen.breakpoint == 'xl' ||
                this.$screen.breakpoint == 'xxl'
            ) {
                window.removeEventListener('scroll', this.handleScroll);
            }
        },
        watch: {
            showMouse: {
                handler(value) {
                    if (value) {
                        this.animate(false);
                    }
                },
            },
        },
    };
</script>
<style scoped lang="scss">
.animate-scroll {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 120px;
  &.show {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }
  .mouse {
    width: 20px;
    height: 34px;
    border: 2px solid $text;
    border-radius: 10px;
    position: relative;
    .scroll {
      position: absolute;
      height: 7px;
      width: 2px;
      background-color: $text;
      border-radius: 2px;
      top: 5px;
      transition: top 500ms ease-in-out;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      &.down {
        transition: top 500ms ease-in-out;
        height: 5px;
        top: 12px;
      }
    }
  }
  p {
    font-size: 16px;
    color: $text;
  }
}
</style>
