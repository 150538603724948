<template>
    <div class="slider">
        <div class="image">
            <div
                ref="carousel"
                class="slider-wrapper"
            >
                <v-lazy-image
                    :key="`slide-0`"
                    :src="sliders[0].images['full']"
                    :srcset="
                        sliders[0].images['h-270'] +
                            ' 768w, ' +
                            sliders[0].images['h-340'] +
                            ' 1024w, ' +
                            sliders[0].images['h-480'] +
                            ' 1440w,' +
                            sliders[0].images['h-640'] +
                            ' 1920w,' +
                            sliders[0].images['full'] +
                            ' 2560w'
                    "
                    :alt="sliders[0].title"
                    height="480"
                    width="auto"
                    :class="{ current: currentSlide == 0 }"
                    :src-placeholder="sliders[0].images['h-75']"
                />
                <img
                    v-for="(slide, index) in slides"
                    :key="`slide-${index+1}`"
                    :src="slide.images['full']"
                    :srcset="
                        slide.images['h-270'] +
                            ' 768w, ' +
                            slide.images['h-340'] +
                            ' 1024w, ' +
                            slide.images['h-480'] +
                            ' 1440w,' +
                            slide.images['full'] +
                            ' 1920w'
                    "
                    :alt="slide.title"
                    height="480"
                    width="auto"
                    :class="{ current: currentSlide == index+1 }"
                >
            </div>
            <div class="controls">
                <b-button
                    variant="text"
                    class="link icon"
                    aria-label="slide-ul anterior"
                    @click="showPrevious"
                >
                    <chevron-left-icon />
                </b-button>
                <b-button
                    variant="text"
                    class="link icon"
                    aria-label="slide-ul urmator"
                    @click="showNext"
                >
                    <chevron-right-icon />
                </b-button>
            </div>
        </div>
        <div class="info">
            <b-col
                cols="12"
                lg="8"
                xl="9"
            >
                <div
                    v-if="sliderLength"
                    class="slider-nav"
                >
                    <div
                        v-for="(slide, index) in sliders"
                        :key="`slide-thumb-${index}`"
                        class="description"
                        :class="{ current: currentSlide == index }"
                    >
                        <b-col
                            cols="5"
                            md="4"
                            class="text-center"
                        >
                            <a
                                v-if="slide.link_is_external"
                                :href="slide.link"
                                target="_blank"
                            >
                                <img
                                    :src="slide.images['thumbnail']"
                                    width="100"
                                    height="100"
                                    :alt="slide.title"
                                >
                                <PlusIcon />
                            </a>
                            <router-link
                                v-else
                                :to="slide.link"
                            >
                                <img
                                    width="100"
                                    height="100"
                                    :src="slide.images['thumbnail']"
                                    :alt="slide.title"
                                >
                                <PlusIcon />
                            </router-link>
                        </b-col>
                        <b-col
                            cols="7"
                            md="8"
                        >
                            <p>
                                {{ slide.description }}
                            </p>
                        </b-col>
                    </div>
                </div>
            </b-col>
            <b-col
                cols="3"
                xl="3"
                lg="4"
                class="progress-col"
            >
                <div v-if="sliderLength">
                    <div class="numbers">
                        <span>0{{ slidePositions }}/</span>
                        <span>0{{ sliderLength }}</span>
                    </div>
                    <div :class="'bar w-' + slidePositions" />
                </div>
            </b-col>
        </div>
    </div>
</template>

<script>
    import VLazyImage from 'v-lazy-image/v2';
    import ChevronLeftIcon from 'vue-feather-icons/icons/ChevronLeftIcon';
    import ChevronRightIcon from 'vue-feather-icons/icons/ChevronRightIcon';
    import PlusIcon from 'vue-feather-icons/icons/PlusIcon';
    export default {
        name: 'HomeHeroBanner',
        components: {
            PlusIcon,
            ChevronRightIcon,
            ChevronLeftIcon,
            VLazyImage
        },
        props: {
            sliders: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                currentSlide: 0,
            };
        },
        computed: {
            sliderLength() {
                return this.sliders.length;
            },
            slidePositions() {
                return this.currentSlide + 1;
            },
            slides(){
                let slides = this.sliders;
                return slides.slice(1);
            }
        },
        methods: {
            showNext() {
                if (this.currentSlide >= this.sliderLength - 1) {
                    this.currentSlide = 0;
                } else {
                    this.currentSlide += 1;
                }
            },
            showPrevious() {
                if (this.currentSlide <= 0) {
                    this.currentSlide = this.sliderLength - 1;
                } else {
                    this.currentSlide -= 1;
                }
            },
        },
    };
</script>
<style scoped lang="scss">
.slider {
  @include media-breakpoint-between(sm, md) {
    display: flex;
  }
  .image {
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      aspect-ratio: 3 / 2;
    }
    @include media-breakpoint-between(sm, md) {
      width: 50%;
    }
    @include media-breakpoint-down(md) {
      height: 270px;
    }
    .slider-wrapper {
      position: relative;
      top: 0;
      right: 0vw;
      height: 100%;
      @include media-breakpoint-up(md) {
        width: 50vw;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        z-index: 0;
        transition: opacity 250ms ease-out;
        &.current {
          opacity: 1;
          z-index: 1;
          transition: opacity 250ms ease-out;
        }
        @include media-breakpoint-down(md) {
          max-height: 270px;
        }
      }
    }
    .controls {
      background-color: $white;
      position: absolute;
      display: flex;
      left: 0;
      bottom: 0;
      z-index: 2;
      @include media-breakpoint-down(md) {
        left: initial;
        right: 0;
      }
      .btn {
        margin: 0;
        transition-timing-function: ease-in;
        transition: 300ms;
        border-radius: 0;
        @include media-breakpoint-up(md) {
          &:hover {
            background-color: $secondary;
            svg {
              color: $white;
            }
          }
          &:focus-visible,
          &:focus {
            box-shadow: none;
            border: none !important;
            outline: none;
          }
        }
      }
    }
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      height: 155px;
    }
    @include media-breakpoint-between(sm, md) {
      width: 50%;
      order: 1;
      height: 270px;
      > div {
        height: 100%;
      }
    }
    @include media-breakpoint-down(sm) {
      height: 155px;
    }
    > div {
      padding: 0;
      height: 100%;
      .slider-nav {
        height: 100%;
        position: relative;
        .description {
          @include media-breakpoint-between(sm, md) {
            height: 100% !important;
          }
          background-color: $black;
          color: $white;
          display: flex !important;
          align-items: center;
          justify-content: center;
          height: 155px;
          padding: 0.5rem;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          z-index: 0;
          transition: opacity 150ms ease-out;
          &.current {
            opacity: 1;
            z-index: 1;
            transition: opacity 150ms ease-out;
          }
          > div {
            position: relative;
            img {
              width: 100%;
              max-width: 180px;
              max-height: 120px;
              object-fit: contain;
            }
            p {
              color: $white;
              margin: 0;
              padding: 0px 1rem;
              @include media-breakpoint-down(sm) {
                font-size: 12px;
              }
            }
            svg {
              height: 16px;
              width: 16px;
              color: $secondary;
              position: absolute;
              top: -5px;
              right: 0;
            }
          }
        }
      }
      &.progress-col {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 24px;
        @include media-breakpoint-down(lg) {
          display: none;
        }

        > div {
          .numbers {
            font-size: 38px;
            font-weight: 600;
            text-align: center;
            span {
              color: $black;
              &:last-of-type {
                color: $gray;
              }
            }
          }
          .bar {
            width: 100%;
            height: 4px;
            background-color: $gray;
            position: relative;
            &::before {
              position: absolute;
              content: "";
              height: 4px;
              background-color: $black;
            }
            &.w-1 {
              &::before {
                width: 20%;
              }
            }
            &.w-2 {
              &::before {
                width: 40%;
              }
            }
            &.w-3 {
              &::before {
                width: 60%;
              }
            }
            &.w-4 {
              &::before {
                width: 80%;
              }
            }
            &.w-5 {
              &::before {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
