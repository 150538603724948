<template>
    <div
        class="container-fluid p-0"
    >
        <b-row class="banner">
            <b-col
                cols="12"
                md="6"
                class="p-0 centered-box"
            >
                <home-hero-text
                    v-if="existData"
                    :banner="sliders"
                />
                <home-hero-text-loader v-if="loading && !existData" />
                <home-hero-scroll v-if="isNotMobile" />
            </b-col>
            <b-col
                cols="12"
                md="6"
                class="p-0"
            >
                <home-hero-banner
                    v-if="existData"
                    :sliders="sliders.elements"
                />
                <home-hero-banner-loader v-if="loading && !existData" />
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    import HomeHeroBannerLoader from '@/components/loaders/home/HomeHeroBannerLoader';
    import HomeHeroTextLoader from '@/components/loaders/home/HomeHeroTextLoader';

    import HomeHeroBanner from './HomeHeroBanner';
    import HomeHeroScroll from './HomeHeroScroll';
    import HomeHeroText from './HomeHeroText';

    export default {
        name: 'HomeHero',
        components: {
            HomeHeroBanner,
            HomeHeroScroll,
            HomeHeroText,
            HomeHeroTextLoader,
            HomeHeroBannerLoader,
        },
        computed: {
            ...mapState('home', ['sliders', 'loading']),
            existData() {
                return this.sliders.elements.length > 0;
            },
            isNotMobile() {
                return this.$screen.breakpoint != 'md';
            },
        },
    };
</script>
<style scoped lang="scss">
.banner {
  box-shadow: $shadow-menu;
  margin: 0;
  @include media-breakpoint-down(md) {
    box-shadow: none;
  }
  .centered-box {
    position: relative;
    @include media-breakpoint-down(md) {
      order: 1;
    }
    .animate-scroll {
      opacity: 0;
      transition: opacity 300ms ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      position: absolute;
      bottom: 50px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      width: 120px;
      &.show {
        opacity: 1;
        transition: opacity 300ms ease-in-out;
      }
      .mouse {
        width: 20px;
        height: 34px;
        border: 2px solid $text;
        border-radius: 10px;
        position: relative;
        .scroll {
          position: absolute;
          height: 7px;
          width: 2px;
          background-color: $text;
          border-radius: 2px;
          top: 5px;
          transition: top 500ms ease-in-out;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          &.down {
            transition: top 500ms ease-in-out;
            height: 5px;
            top: 12px;
          }
        }
      }
      p {
        font-size: 16px;
        color: $text;
      }
    }
  }
}
</style>
