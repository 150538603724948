<template>
    <div class="home">
        <section class="hero">
            <home-hero />
        </section>
        <section class="featured-categories">
            <home-featured-categories />
        </section>
        <section class="brand">
            <home-brand-listing />
        </section>
        <section class="best-sellers">
            <home-best-sellers />
        </section>
        <section class="promotions">
            <home-promotions />
        </section>
        <section
            v-if="scrollBasedVisibility"
            class="products"
        >
            <home-new-products />
        </section>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import HomeFeaturedCategories from './home/HomeFeaturedCategories';
    import HomeHero from './home/HomeHero';

    const HomePromotions = () =>
        import(/* webpackChunkName: "home-promotions" */ './home/HomePromotions');
    const HomeNewProducts = () =>
        import(/* webpackChunkName: "home-new-products" */ './home/HomeNewProducts');
    const HomeBrandListing = () =>
        import(/* webpackChunkName: "home-brands" */ './home/HomeBrandListing');
    const HomeBestSellers = () =>
        import(/* webpackChunkName: "home-best-sellers" */ './home/HomeBestSellers');

    export default {
        name: 'HomePage',
        components: {
            HomeHero,
            HomeFeaturedCategories,
            HomeBrandListing,
            HomeBestSellers,
            HomePromotions,
            HomeNewProducts,
        },
        computed: {
            ...mapState('general', ['scrollBasedVisibility']),
        },
        methods: {
            ...mapActions('home', ['getSections']),
            async loadSections() {
                await this.getSections();
            },
        },
        created() {
            this.loadSections();
        },
        watch: {
            $route() {
                this.loadSections();
            },
        },
        metaInfo() {
            return {
                link: [{ rel: 'canonical', href: window.location.origin }],
            };
        },
    };
</script>
<style lang="scss">
@import "src/_scss/components/slick-slider.scss";
.home {
  .container {
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @include media-breakpoint-down(sm) {
        display: block;
      }
      .btn {
        margin-top: 0;
      }
    }
  }
  .products {
    padding-bottom: 40px;
    .add-to-cart,
    .add-to-favorit {
      display: none;
    }
    .actions {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
</style>
