<template>
    <div class="container">
        <h3 class="text-center">
            {{ $t('page.home.featuredCategoriesHeading') }}
        </h3>
        <div class="slider-wrapper">
            <Flicking
                v-if="isResponsive"
                ref="flicking"
                :options="{
                    bound: true,
                    autoResize: true,
                }"
            >
                <div
                    v-for="category in featuredCategories"
                    :key="`category-${featuredCategories.indexOf(category)}`"
                    class="panel slide"
                >
                    <category-card
                        v-if="existData"
                        :category="category"
                    />
                    <div
                        v-if="loading && !existData"
                        class="category"
                    >
                        <home-hero-featured-categories-loader />
                    </div>
                </div>
            </Flicking>
            <b-row v-if="!isResponsive">
                <b-col
                    v-for="category in featuredCategories"
                    :key="`category-${featuredCategories.indexOf(category)}`"
                    cols="3"
                    class="slide"
                >
                    <category-card
                        v-if="existData"
                        :category="category"
                    />
                    <div
                        v-if="loading && !existData"
                        class="category"
                    >
                        <home-hero-featured-categories-loader />
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import { Flicking } from '@egjs/vue-flicking';
    import { mapState } from 'vuex';

    import CategoryCard from '@/components/home/CategoryCard';
    import HomeHeroFeaturedCategoriesLoader from '@/components/loaders/home/HomeHeroFeaturedCategoriesLoader';
    export default {
        name: 'HomeFeaturedCategories',
        components: {
            CategoryCard,
            HomeHeroFeaturedCategoriesLoader,
            Flicking: Flicking,
        },
        data() {
            return {
                screenWidth: 0,
                perPage: [
                    [320, 1.4],
                    [420, 2.2],
                    [576, 3.2],
                    [768, 4],
                ],
                panelsToShow: 1.0,
            };
        },
        computed: {
            ...mapState('home', ['featuredCategories', 'loading']),
            existData() {
                return this.featuredCategories.length > 0;
            },
            isResponsive() {
                return this.screenWidth < 768;
            },
        },
        methods: {
            updatePanelsToShow() {
                this.screenWidth = window.innerWidth;

                for (let i = this.perPage.length - 1; i >= 0; i--) {
                    if (this.screenWidth >= this.perPage[i][0]) {
                        this.panelsToShow = this.perPage[i][1];
                        break;
                    }
                }

                this.updateFlickingOptions();
            },
            updateFlickingOptions() {
                const flicking = this.$refs.flicking;

                if (flicking) {
                    flicking.panels.forEach((panel) => {
                        panel.element.style.width = `${100 / this.panelsToShow}%`;
                    });

                    flicking.resize();
                }
            },
        },
        mounted() {
            this.updatePanelsToShow();
            window.addEventListener('resize', this.updatePanelsToShow);
        },

        unmounted() {
            window.removeEventListener('resize', this.updatePanelsToShow);
        },
    };
</script>
<style lang="css">
@import '~@egjs/vue-flicking/dist/flicking.css';
</style>

<style scoped lang="scss">
.featured-categories {
    .text-center {
        @include media-breakpoint-down(md) {
            text-align: left !important;
        }
    }
    .col-3 {
        &:nth-of-type(2) {
            .category {
                @include media-breakpoint-up(md) {
                    margin-top: 40px;
                }
            }
        }
        &:nth-of-type(3) {
            .category {
                @include media-breakpoint-up(md) {
                    margin-top: -40px;
                }
            }
        }
    }
    .slider-wrapper {
        margin-left: -12px;
        .panel {
            padding: 0 12px;
        }
    }
}
</style>
