<template>
    <ContentLoader
        primary-color="#f6f7f8"
        secondary-color="#eef0f2"
        width="100"
        height="86"
        preserveAspectRatio="none"
        :speed="1"
    >
        <rect
            x="10"
            y="25"
            rx="1"
            ry="1"
            width="50"
            height="10"
        />
        <rect
            x="10"
            y="40"
            rx="1"
            ry="1"
            width="60"
            height="3"
        />
        <rect
            x="10"
            y="46"
            rx="1"
            ry="1"
            width="75"
            height="3"
        />
        <rect
            x="10"
            y="52"
            rx="1"
            ry="1"
            width="75"
            height="3"
        />
        <rect
            x="10"
            y="58"
            rx="1"
            ry="1"
            width="75"
            height="3"
        />
        <rect
            x="10"
            y="66"
            rx="1"
            ry="1"
            width="25"
            height="5"
        />
    </ContentLoader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader';

    export default {
        name:'HomeHeroTextLoader',
        components: {
            ContentLoader,
        },
    };
</script>