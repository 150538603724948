<template>
    <ContentLoader
        primary-color="#f6f7f8"
        secondary-color="#eef0f2"
        width="100"
        height="100"
        preserveAspectRatio="none"
        :speed="1"
    >
        <rect
            x="0"
            y="0"
            rx="4"
            ry="4"
            width="100%"
            height="85%"
        />
        <rect
            x="0"
            y="88%"
            rx="4"
            ry="4"
            width="100%"
            height="12%"
        />
    </ContentLoader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader';

    export default {
        name:'HomeHeroFeaturedCategoriesLoader',
        components: {
            ContentLoader,
        },
    };
</script>