var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider"},[_c('div',{staticClass:"image"},[_c('div',{ref:"carousel",staticClass:"slider-wrapper"},[_c('v-lazy-image',{key:`slide-0`,class:{ current: _vm.currentSlide == 0 },attrs:{"src":_vm.sliders[0].images['full'],"srcset":_vm.sliders[0].images['h-270'] +
                        ' 768w, ' +
                        _vm.sliders[0].images['h-340'] +
                        ' 1024w, ' +
                        _vm.sliders[0].images['h-480'] +
                        ' 1440w,' +
                        _vm.sliders[0].images['h-640'] +
                        ' 1920w,' +
                        _vm.sliders[0].images['full'] +
                        ' 2560w',"alt":_vm.sliders[0].title,"height":"480","width":"auto","src-placeholder":_vm.sliders[0].images['h-75']}}),_vm._l((_vm.slides),function(slide,index){return _c('img',{key:`slide-${index+1}`,class:{ current: _vm.currentSlide == index+1 },attrs:{"src":slide.images['full'],"srcset":slide.images['h-270'] +
                        ' 768w, ' +
                        slide.images['h-340'] +
                        ' 1024w, ' +
                        slide.images['h-480'] +
                        ' 1440w,' +
                        slide.images['full'] +
                        ' 1920w',"alt":slide.title,"height":"480","width":"auto"}})})],2),_c('div',{staticClass:"controls"},[_c('b-button',{staticClass:"link icon",attrs:{"variant":"text","aria-label":"slide-ul anterior"},on:{"click":_vm.showPrevious}},[_c('chevron-left-icon')],1),_c('b-button',{staticClass:"link icon",attrs:{"variant":"text","aria-label":"slide-ul urmator"},on:{"click":_vm.showNext}},[_c('chevron-right-icon')],1)],1)]),_c('div',{staticClass:"info"},[_c('b-col',{attrs:{"cols":"12","lg":"8","xl":"9"}},[(_vm.sliderLength)?_c('div',{staticClass:"slider-nav"},_vm._l((_vm.sliders),function(slide,index){return _c('div',{key:`slide-thumb-${index}`,staticClass:"description",class:{ current: _vm.currentSlide == index }},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"5","md":"4"}},[(slide.link_is_external)?_c('a',{attrs:{"href":slide.link,"target":"_blank"}},[_c('img',{attrs:{"src":slide.images['thumbnail'],"width":"100","height":"100","alt":slide.title}}),_c('PlusIcon')],1):_c('router-link',{attrs:{"to":slide.link}},[_c('img',{attrs:{"width":"100","height":"100","src":slide.images['thumbnail'],"alt":slide.title}}),_c('PlusIcon')],1)],1),_c('b-col',{attrs:{"cols":"7","md":"8"}},[_c('p',[_vm._v(" "+_vm._s(slide.description)+" ")])])],1)}),0):_vm._e()]),_c('b-col',{staticClass:"progress-col",attrs:{"cols":"3","xl":"3","lg":"4"}},[(_vm.sliderLength)?_c('div',[_c('div',{staticClass:"numbers"},[_c('span',[_vm._v("0"+_vm._s(_vm.slidePositions)+"/")]),_c('span',[_vm._v("0"+_vm._s(_vm.sliderLength))])]),_c('div',{class:'bar w-' + _vm.slidePositions})]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }